import { FC } from 'react';

interface IProps {
  error?: string;
}

export const ErrorPage: FC<IProps> = props => {
  return (
    <div>
      <h2>An error has occurred: {props.error || 'unknown error'}</h2>
    </div>
  );
}
import { ITask } from '../types/models/Task';

import { ApiService } from './ApiService';

export interface ITaskService {
  getTasks(): Promise<ITask[]>;
  createTask(task: ITask): Promise<void>;
  deleteTask(taskId: number): Promise<void>;
  updateTask(taskId: number, task: ITask): Promise<void>;
}

export class TaskService extends ApiService implements ITaskService {
  public getTasks = (): Promise<ITask[]> =>
    this.get<ITask[]>('/api/task/getTasks');

  public createTask = (task: ITask): Promise<void> =>
    this.execute('POST', '/api/task/createTask', JSON.stringify(task)).then(
      async (res) => {
        if (res.status > 299) {
          const b = await res.json();
          throw new Error(b?.message || 'Failed to create task');
        }
      }
    );

  public updateTask = (taskId: number, task: ITask) =>
    this.execute(
      'POST',
      `/api/task/${taskId}/updateTask`,
      JSON.stringify(task)
    ).then(async (res) => {
      if (res.status > 299) {
        const b = await res.json();
        throw new Error(b?.message || 'Failed to update task');
      }
    });

  public deleteTask = (taskId: number): Promise<void> =>
    this.execute('DELETE', `/api/task/${taskId}`).then(async (res) => {
      if (res.status > 299) {
        const b = await res.json();
        throw new Error(b?.message || 'Failed to delete task');
      }
    });
}

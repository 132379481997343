import { FC, useCallback, useContext, useEffect } from 'react';
import {
  CommandBar,
  ICommandBarItemProps,
  PrimaryButton,
} from '@fluentui/react';

import { TaskDialog } from '../../components/dialogs/TaskDialog';
import { TaskList } from '../../components/lists/TaskList';

import { TaskContext } from '../../context/TaskContext';
import { PageContext } from '../../context/PageContext';

import { ITask } from '../../types/models/Task';

export const TaskPage: FC = () => {
  const {
    state: { tasks, isInitialised, selectedTask, isDialogOpen, taskForm },
    actions: {
      getTasks,
      createTask,
      updateTask,
      deleteTask,
      setDialogOpen,
      setSelectedTask,
      setTaskForm,
    },
  } = useContext(TaskContext);

  const {
    actions: { confirmAction },
    state: { isLoading, errorState },
  } = useContext(PageContext);

  //#region Initialization
  const refreshTasks = useCallback(async () => {
    await getTasks();
  }, [getTasks]);

  useEffect(() => {
    if (!isInitialised && !errorState.active) {
      refreshTasks();
    }
  }, [isInitialised, refreshTasks, errorState]);

  const commandBarFarItems: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Sync' },
      disabled: isLoading,
      onClick: () => {
        refreshTasks();
      },
    },
  ];
  //#endregion

  //#region Event handlers
  const handleTaskSelected = (task: ITask) => {
    setSelectedTask(task);
    taskForm.setFieldValue('Name', task.name);
    taskForm.setFieldValue('Description', task.description);
    taskForm.setFieldValue(
      'Budget.Hours',
      task.budget.hours + task.budget.days * 24
    );
    setTaskForm(taskForm);

    setDialogOpen(true);
  };

  const handleTaskDialogDismiss = () => {
    setDialogOpen(false);
    taskForm.reset();
    setSelectedTask(null);
  };

  const handleFieldChanged = useCallback(
    (name: string, newValue: any) => {
      taskForm.handleFieldChanged(name, newValue);
      setTaskForm(taskForm);
    },
    [taskForm, setTaskForm]
  );

  const handleTaskDeleteConfirmation = useCallback(
    async (task: ITask) => {
      await deleteTask(task);
      await refreshTasks();
    },
    [deleteTask, refreshTasks]
  );

  const handleTaskDelete = useCallback(
    (task: ITask) => {
      confirmAction(
        'Delete task confirmation',
        `Are you sure you want to delete ${task.name}?`,
        async () => await handleTaskDeleteConfirmation(task)
      );
    },
    [confirmAction, handleTaskDeleteConfirmation]
  );
  //#endregion

  return (
    <div>
      <CommandBar items={[]} farItems={commandBarFarItems} />

      <div style={{ padding: '20px 5px 10px 20px' }}>
        <PrimaryButton
          text="Create Task"
          onClick={() => setDialogOpen(true)}
          disabled={isLoading}
        />

        <TaskList
          tasks={tasks}
          onTaskSelect={handleTaskSelected}
          onTaskDelete={handleTaskDelete}
        />
      </div>

      <TaskDialog
        isNew={!selectedTask}
        isOpen={isDialogOpen}
        onDismiss={handleTaskDialogDismiss}
        onSave={() =>
          selectedTask
            ? updateTask(selectedTask.taskId, taskForm.get<ITask>())
            : createTask(taskForm.get<ITask>())
        }
        values={taskForm.getValues()}
        onFieldChanged={handleFieldChanged}
      />
    </div>
  );
};

import { adalApiFetch, appConfig } from '../config';

export class ApiService {

  private baseUrl: string;

  constructor() {
    this.baseUrl = appConfig.apiUrl;
  }

  protected execute(method: string, url: string, body?: any): Promise<Response> {
    const filteredUrl: string = url.startsWith('/') ? url : `/${url}`;

    // return fetch(this.baseUrl + filteredUrl, {
    //   method,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${this.bearerToken}`
    //   },
    //   body: body || undefined
    // });
    return adalApiFetch(
      fetch,
      this.baseUrl + filteredUrl,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        method,
        body
      });
  }

  protected get<T>(url: string) {
    return this.execute('GET', url)
      .then(res => res.json())
      .then(res => res as T);
  }

  protected post<T>(url: string, body?: any) {
    return this.execute('POST', url, body)
      .then(res => res.json())
      .then(res => res as T);
  }

  protected put<T>(url: string, body?: any) {
    return this.execute('PUT', url, body)
      .then(res => res.json())
      .then(res => res as T);
  }

  protected patch<T>(url: string, body?: any) {
    return this.execute('PATCH', url, body)
      .then(res => res.json())
      .then(res => res as T);
  }

  protected delete<T>(url: string, body?: any) {
    return this.execute('DELETE', url, body)
      .then(res => res.json())
      .then(res => res as T);
  }
}
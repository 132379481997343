import { Reducer } from 'react';
import { Action } from '../../types/Action';

export const Actions = {
  SET_ISLOADING: 'SET_ISLOADING',
  SET_CONFIRMDIALOG_STATE: 'SET_CONFIRMDIALOG_STATE',
  SET_ERRORSTATE: 'SET_ERRORSTATE'
};

export interface IErrorState {
  active: boolean;
  message?: string;
}

export interface IPageState {
  isLoading: boolean;
  errorState: IErrorState;
  confirmationModal: {
    isOpen: boolean;
    title: string;
    message: string;
    onConfirmAsync?: () => Promise<void | any>;
    onConfirm?: () => void;
  };
}

export const defaultState: IPageState = {
  isLoading: false,
  errorState: {
    active: false,
    message: ''
  },
  confirmationModal: {
    isOpen: false,
    title: 'Confirm',
    message: 'Are you sure?',
    onConfirm: () => { throw new Error('Application error: Confirmation callback not implemented'); }
  }
};

const reducer: Reducer<IPageState, Action<any>> = (state, action) => {

  switch (action.type) {
    
    case Actions.SET_ISLOADING:
      if (typeof action.payload !== 'boolean') {
        throw new Error('Application error: `isLoading` must be a boolean');
      }
      return {
        ...state,
        isLoading: action.payload
      };

    case Actions.SET_CONFIRMDIALOG_STATE:
      return {
        ...state,
        confirmationModal: action.payload
      };

    case Actions.SET_ERRORSTATE:
      return {
        ...state,
        errorState: action.payload
      };

    default:
      return state;
  }
};
export default reducer;
import {
  Calendar,
  CommandBar,
  ICommandBarItemProps,
  PrimaryButton,
  CommandBarButton,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { FC, useCallback, useContext, useEffect } from 'react';
import { ActivityDialog } from '../../components/dialogs/ActivityDialog';
import { ActivityList } from '../../components/lists/ActivityList';
import { TotalTimeList } from '../../components/lists/TotalTimeList';
import { ActivityContext } from '../../context/ActivityContext';
import { PageContext } from '../../context/PageContext';
import { TaskContext } from '../../context/TaskContext';
import { IActivity } from '../../types/models/Activity';

import s from './ActivityPage.module.scss';

export const ActivityPage: FC = () => {
  const {
    actions: {
      getActivities,
      setActivityForm,
      setDialogOpen,
      createActivity,
      updateActivity,
      deleteActivity,
      setIsNewActivity,
      setSelectedActivity,
      setDate,
      setDatePickOpen,
    },
    state: {
      activities,
      isInitialised,
      activityForm,
      date,
      isDialogOpen,
      isActivityNew,
      selectedActivity,
      isDatePickOpen,
    },
  } = useContext(ActivityContext);
  const {
    actions: { getTasks },
    state: taskState,
  } = useContext(TaskContext);
  const {
    state: { isLoading },
    actions: { setError, confirmAction },
  } = useContext(PageContext);

  const buttonsDisabled = isLoading;

  let commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'back',
      iconProps: { iconName: 'Back' },
      disabled: buttonsDisabled,
      onClick: () => setDate(dayjs(date).subtract(1, 'day').toDate()),
    },
    {
      key: 'forward',
      iconProps: { iconName: 'Forward' },
      disabled: buttonsDisabled,
      onClick: () => setDate(dayjs(date).add(1, 'day').toDate()),
    },
    {
      key: 'setDate',
      disabled: buttonsDisabled,
      onRender: () => (
        <div className={s.dateButton}>
          <div className={s.buttonContainer}>
            <CommandBarButton
              styles={{ root: { height: '100%' } }}
              toggle
              checked={isDatePickOpen}
              disabled={buttonsDisabled}
              text={dayjs(date).format('D MMM YYYY (ddd)')}
              onClick={() => setDatePickOpen(!isDatePickOpen)}
            />
          </div>
          <div
            className={s.pickerContainerContainer}
            style={{ display: isDatePickOpen ? 'block' : 'none' }}
          >
            <div className={s.pickerContainer}>
              <Calendar
                value={date}
                onSelectDate={(dt) => setDate(dt)}
                showMonthPickerAsOverlay
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  if (
    date.getFullYear() !== new Date().getFullYear() ||
    date.getMonth() !== new Date().getMonth() ||
    date.getDate() !== new Date().getDate()
  ) {
    commandBarItems = [
      ...commandBarItems,
      {
        key: 'resetDate',
        iconProps: { iconName: 'GoToToday' },
        disabled: buttonsDisabled,
        onClick: () => setDate(dayjs().hour(0).toDate()),
      },
    ];
  }

  const commandBarFarItems: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Sync' },
      disabled: buttonsDisabled,
      onClick: () =>
        (function () {
          refresh();
        })(),
    },
  ];

  //#region refreshing junk
  const refreshActivities = useCallback(
    async (isRefresh?: boolean) => {
      if (!isInitialised || isRefresh /* && !errorState.active*/) {
        try {
          await getActivities(date);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError(
              'Error fetching activities. Please contact your system administrator.'
            );
          }
        }
      }
    },
    [getActivities, isInitialised, setError, date]
  );

  const refreshTasks = useCallback(
    async (isRefresh?: boolean) => {
      if (!taskState.isInitialised || isRefresh /* && !errorState.active*/) {
        try {
          await getTasks();
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError(
              'Error fetching tasks. Please contact your system administrator.'
            );
          }
        }
      }
    },
    [getTasks, taskState, setError]
  );

  const refresh = useCallback(async () => {
    await Promise.all([refreshActivities(true), refreshTasks(true)]);
  }, [refreshActivities, refreshTasks]);
  //#endregion

  //#region event handlers
  const handleFieldChanged = useCallback(
    (name: string, newValue: string | number) => {
      activityForm.setFieldValue(name, newValue);
      setActivityForm(activityForm);
    },
    [activityForm, setActivityForm]
  );

  const handleSelectCreateActivity = useCallback(() => {
    activityForm.reset();

    const dateForTime = dayjs(date)
      .hour(dayjs().hour())
      .minute(dayjs().minute());

    activityForm.setFieldValue(
      'StartTime',
      dayjs(dateForTime).format('hh:mm a')
    );
    setActivityForm(activityForm);
    setDialogOpen(true);
    setIsNewActivity(true);
  }, [activityForm, setActivityForm, setDialogOpen, date, setIsNewActivity]);

  const handleSaveActivity = useCallback(async () => {
    if (isActivityNew) {
      await createActivity(activityForm.get<IActivity>());
    } else {
      await updateActivity(
        selectedActivity.activityId,
        activityForm.get<IActivity>()
      );
    }
    await refreshActivities(true);
  }, [
    selectedActivity,
    createActivity,
    refreshActivities,
    updateActivity,
    isActivityNew,
    activityForm,
  ]);

  const handleDeleteActivity = useCallback(
    (activity: IActivity) => {
      confirmAction(
        'Delete activity confirmation',
        `Are you sure want to delete ${activity.overview}?`,
        async () => {
          await deleteActivity(activity.activityId);
          await refreshActivities(true);
        }
      );
    },
    [confirmAction, deleteActivity, refreshActivities]
  );

  const handleEditActivity = useCallback(
    (activity: IActivity) => {
      activityForm.reset();

      activityForm.setFieldValue('Overview', activity.overview);
      activityForm.setFieldValue('TaskId', activity.task.taskId);
      activityForm.setFieldValue(
        'StartTime',
        dayjs(activity.startTime).format('hh:mm a')
      );
      activityForm.setFieldValue(
        'EndTime',
        (activity.endTime && dayjs(activity.endTime).format('hh:mm a')) || ''
      );

      setActivityForm(activityForm);
      setSelectedActivity(activity);
      setIsNewActivity(false);
      setDialogOpen(true);
    },
    [
      setActivityForm,
      setDialogOpen,
      setSelectedActivity,
      setIsNewActivity,
      activityForm,
    ]
  );
  //#endregion

  useEffect(() => {
    refreshActivities(false);
    refreshTasks(false);
  }, [refreshActivities, refreshTasks]);

  return (
    <div>
      <CommandBar
        items={commandBarItems}
        farItems={commandBarFarItems}
        styles={{ root: { paddingTop: 5 } }}
      />
      <div style={{ padding: '20px 5px 10px 20px' }}>
        <PrimaryButton
          text="Create Activity"
          onClick={() => handleSelectCreateActivity()}
          disabled={isLoading}
        />
        <ActivityList
          items={activities}
          onSelect={handleEditActivity}
          onDelete={handleDeleteActivity}
        />
        <TotalTimeList items={activities} />
      </div>

      <ActivityDialog
        isNew={isActivityNew}
        isOpen={isDialogOpen}
        onDismiss={() => setDialogOpen(false)}
        onFieldChanged={handleFieldChanged}
        onSave={handleSaveActivity}
        tasks={taskState.tasks}
        values={activityForm.getValues()}
        isValid={activityForm.isValid()}
        forDate={isActivityNew ? date : selectedActivity.startTime}
      />
    </div>
  );
};

interface IAuthenticationConfig {
  redirectUrl: string;
  directoryId: string;
  tenant: string;
  clientId: string;
  resource: string;
}

interface IAppConfig {
  isDevelopment: boolean;
  apiUrl: string;
  uiVersion: string;
  authentication: IAuthenticationConfig;
}

const isDev = window.location.hostname.toLowerCase().indexOf('localhost') > -1;
const appConfig: IAppConfig = {
  isDevelopment: isDev,
  apiUrl: process.env.REACT_APP_BASE_API_URL,
  uiVersion: process.env.REACT_APP_UIVERSION,
  authentication: {
    redirectUrl: `${window.location.protocol}//${window.location.host}/oidc/callback`,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    resource: process.env.REACT_APP_AUTH_RESOURCE,
    directoryId: process.env.REACT_APP_AUTH_DIRECTORY_ID,
    tenant: process.env.REACT_APP_AUTH_TENANT_NAME
  }
};
export { appConfig }
import moment from 'moment';

export const formatTimeToDateTime = (timeString: string, date: Date): moment.Moment | null => {
  const timeFormats = ['h:m a', 'h:ma', 'H:m'];
  if (!moment(timeString, timeFormats).isValid()) {
    return null;
  }

  const newTime = moment(timeString, timeFormats).set('seconds', 0);
  const dateTime = moment(date)
    .hour(newTime.hour())
    .minute(newTime.minute())
    .seconds(newTime.seconds());

  return dateTime;
};
import { loadTheme } from '@fluentui/react';

loadTheme({
palette: {
  themePrimary: '#333385',
  themeLighterAlt: '#f4f4fa',
  themeLighter: '#d4d4eb',
  themeLight: '#b2b2da',
  themeTertiary: '#7272b6',
  themeSecondary: '#434393',
  themeDarkAlt: '#2d2d77',
  themeDark: '#262665',
  themeDarker: '#1c1c4a',
  neutralLighterAlt: '#faf9f8',
  neutralLighter: '#f3f2f1',
  neutralLight: '#edebe9',
  neutralQuaternaryAlt: '#e1dfdd',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c6c4',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#000000',
  white: '#ffffff',
}});
import { FC } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { TaskPage } from '../TaskPage';
import { ActivityPage } from '../ActivityPage';
import { TaskContextProvider } from '../../context/TaskContext';
import { ActivityContextProvider } from '../../context/ActivityContext';
import { SearchPage } from '../SearchPage';
import { SearchContextProvider } from '../../context/SearchContext';

export const LaunchPage: FC = () => {
  return (
    <div>
      <ActivityContextProvider>
        <TaskContextProvider>
          <SearchContextProvider>
            {/* TODO: add a splash doovalakky here */}
            <Pivot>
              <PivotItem headerText="My Day" itemIcon="PrimaryCalendar">
                <ActivityPage />
              </PivotItem>

              <PivotItem headerText="Manage Tasks" itemIcon="CheckList">
                <TaskPage />
              </PivotItem>

              <PivotItem headerText="Search" itemIcon="SearchCalendar">
                <SearchPage />
              </PivotItem>
            </Pivot>
          </SearchContextProvider>
        </TaskContextProvider>
      </ActivityContextProvider>
    </div>
  );
};

import { FC, useContext } from 'react';
import {
  Modal,
  PrimaryButton,
  DefaultButton,
  Stack,
  Text
} from '@fluentui/react';
import { PageContext } from '../../context/PageContext';

interface IProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirmAsync?: () => Promise<any | void>;
  onConfirm?: () => void;
  onDismiss: () => void;
}

export const ConfirmationModal: FC<IProps> = ({
  isOpen,
  title,
  message,
  onConfirmAsync,
  onConfirm,
  onDismiss
}) => {

  const {
    state: { isLoading }
  } = useContext(PageContext);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
    >
      <Stack
        tokens={{ childrenGap: 20 }}
        styles={{ root: { padding: '10px 10px 0 10px' } }}
        grow={true}
      >
        <div>
          <Text variant="xLarge">{title}</Text>
        </div>
        <p>{message}</p>
        <div>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
            <PrimaryButton
              text="Yes"
              onClick={async () => {
                if (onConfirmAsync) {
                  await onConfirmAsync();
                } else if (onConfirm) {
                  onConfirm();
                } else {
                  throw new Error('Application error: onConfirm or onConfirmAsync must be specified');
                }
                onDismiss();
              }}
              disabled={isLoading}
            />
            <DefaultButton
              text="No"
              onClick={onDismiss}
              disabled={isLoading}
            />
          </Stack>
        </div>
      </Stack>
    </Modal>
  );
}
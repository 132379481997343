import { Position, SpinButton, Stack, TextField } from '@fluentui/react';
import { FC, useContext } from 'react';
import { PageContext } from '../../context/PageContext';
import { ITask } from '../../types/models/Task';
import { Dialog } from './Dialog';

interface IProps {
  isOpen: boolean;
  isNew: boolean;
  values?: { [key: string]: string | number };
  onSave: (data: ITask) => Promise<void | any>
  onDismiss: () => void;
  onFieldChanged: (name: string, newValue: string | number) => void;
}

export const TaskDialog: FC<IProps> = ({
  isOpen,
  isNew,
  values,
  onDismiss,
  onSave,
  onFieldChanged
}) => {

  const {
    state: { isLoading }
  } = useContext(PageContext);

  return (
    <Dialog
      isOpen={isOpen}
      title={isNew ? 'New task' : 'Edit task'}
      onDismiss={onDismiss}
      primaryAction={{
        text: isNew ? 'Create' : 'Save',
        iconProps: { iconName: isNew ? 'Accept' : 'Save' },
        disabled: isLoading,
        onClick: async () => {
          await onSave(null);
          onDismiss();
        }
      }}
      subText={`Complete the fields below to ${(isNew ? 'create a new ' : 'confirm changes to the ')} task`}
    >
      {/* Name & description */}
      <TextField
        label="Name"
        onChange={(ev, newValue) => onFieldChanged('Name', newValue)}
        value={values['Name'] as string}
        required
      />
      <TextField
        label="Description"
        multiline={true}
        autoAdjustHeight={true}
        rows={3}
        onChange={(ev, newValue) => onFieldChanged('Description', newValue)}
        value={values['Description'] as string}
      />

      {/* Budget */}
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <SpinButton
          label="Budget (hours)"
          labelPosition={Position.top}
          defaultValue="0"
          min={0}
          step={1}
          onChange={(ev, newValue) => onFieldChanged('Budget.Hours', Number(newValue))}
          value={values['Budget.Hours']?.toString()}
        />
      </Stack>
    </Dialog>
  );
}
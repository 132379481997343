import { Reducer } from 'react';
import { Action } from '../../types/Action';
import { IActivity } from '../../types/models/Activity';

export const Actions = {
  SET_QUERY: 'SET_QUERY',
  SET_SEARCHRESULTS: 'SET_SEARCHRESULTS',
};

export interface ISearchState {
  query: string;
  results: IActivity[];
}
export const defaultState: ISearchState = {
  query: '',
  results: [],
};

const reducer: Reducer<ISearchState, Action<any>> = (state, action) => {
  switch (action.type) {
    case Actions.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case Actions.SET_SEARCHRESULTS:
      return {
        ...state,
        results: action.payload
      };

    default:
      return state;
  }
};
export default reducer;

import { IActivity } from '../types/models/Activity';
import { ApiService } from './ApiService';

export interface IActivityService {
  getActivities(from: Date, to: Date): Promise<IActivity[]>;
  createActivity(activity: IActivity): Promise<void>;
  deleteActivity(activityId: number): Promise<void>;
  updateActivity(id: number, activity: IActivity): Promise<void>;
}

export class ActivityService extends ApiService implements IActivityService {
  public getActivities = (from: Date, to: Date): Promise<IActivity[]> =>
    this.post<IActivity[]>(
      '/api/activity/getActivities',
      JSON.stringify({ from, to })
    );

  public searchActivities = (query: string): Promise<IActivity[]> =>
    this.execute(
      'POST',
      '/api/activity/search',
      JSON.stringify({ query })
    ).then(async (res) => {
      if (res.status > 200) {
        const data = await res.json();
        throw new Error(
          data?.message
            ? data.message
            : `Failed to search ${JSON.stringify(data as any)}`
        );
      }
      return res.json() as Promise<IActivity[]>;
    });

  public createActivity = (activity: IActivity): Promise<void> =>
    this.execute(
      'POST',
      '/api/activity/createActivity',
      JSON.stringify(activity)
    ).then(async (res) => {
      if (res.status > 299) {
        const data = await res.json();
        throw new Error(
          data?.message ||
            `Failed to create activity ${JSON.stringify(activity)}`
        );
      }
    });

  public updateActivity = (id: number, activity: IActivity): Promise<void> =>
    this.execute(
      'POST',
      `/api/activity/${id}/updateActivity`,
      JSON.stringify(activity)
    ).then(async (res) => {
      if (res.status > 299) {
        const data = await res.json();
        throw new Error(
          data?.message || `Failed to update activity - ${res.status}`
        );
      }
    });

  public deleteActivity = (activityId: number): Promise<void> =>
    this.execute('DELETE', `/api/activity/${activityId}`).then(async (res) => {
      if (res.status > 299) {
        const data = await res.json();
        throw new Error(
          data?.message || `Failed to delete activity (${res.status})`
        );
      }
    });
}

import moment from 'moment';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { ActivityService } from '../services/ActivityService';
import { FormManager } from '../services/FormManager';
import { Action } from '../types/Action';
import { IActivity } from '../types/models/Activity';
import { PageContext } from './PageContext';
import reducer, {
  Actions,
  IActivityState,
  initialState,
} from './state/ActivityState';
import { formatTimeToDateTime } from '../utils/formatHelpers';

export interface IActivityContext {
  state: IActivityState;
  actions: {
    setDate(newDate: Date): void;
    getActivities(forDate?: Date): Promise<IActivity[]>;
    createActivity(activity: IActivity): Promise<void>;
    updateActivity(id: number, data: any): Promise<void>;
    deleteActivity(activityId: number): Promise<void>;
    setDialogOpen(isOpen: boolean): void;
    setActivityForm(activityForm: FormManager): void;
    setSelectedActivity(activity: IActivity): void;
    setIsNewActivity(isNew: boolean): void;
    setDatePickOpen(isOpen: boolean): void;
  };
}

export const ActivityContext = createContext<IActivityContext>(
  {} as IActivityContext
);

export const ActivityContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    actions: { setLoading, setError },
  } = useContext(PageContext);

  const activityService = useMemo(() => new ActivityService(), []);

  const getActivities = useCallback(
    async (forDate?: Date) => {
      setLoading(true);

      // get the start of the date, and the end of the date
      const from = moment(forDate || new Date())
        .local()
        .hour(0)
        .minute(0)
        .second(0);
      const to = moment(from).add(1, 'day').subtract(1, 'second');

      try {
        const results = await activityService.getActivities(
          from.utc().toDate(),
          to.utc().toDate()
        );
        dispatch(new Action(Actions.SET_ACTIVITIES, results));
        return results;
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'Failed to fetch activities'
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, activityService, setLoading, setError]
  );

  const createActivity = useCallback(
    async (data: any) => {
      // first of all, let's ensure our object is valid...
      if (!data.TaskId) {
        setError('Task is missing from activity');
        return;
      }
      if (!data.StartTime) {
        setError('Please select a start time');
        return;
      }

      setLoading(true);

      // ensure the format for our start/end times are correct
      data.StartTime = formatTimeToDateTime(
        data.StartTime,
        state.date
      )?.toDate();
      if (data.EndTime) {
        data.EndTime = formatTimeToDateTime(data.EndTime, state.date)?.toDate();
      } else {
        data.EndTime = null;
      }

      try {
        await activityService.createActivity(data);
      } catch (err: Error | any) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError(
            JSON.stringify(err || 'Encountered an error saving activity')
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, activityService, state.date, setError]
  );

  const updateActivity = useCallback(
    async (id: number, data: any) => {
      // quick validation
      if (!data.TaskId) {
        setError('Task is missing from activity');
        return;
      }
      if (!data.StartTime) {
        setError('Please select a start time');
        return;
      }

      setLoading(true);

      // let's go ahead and fix up the fields to ensure they can be deserialized
      data.StartTime = formatTimeToDateTime(
        data.StartTime,
        state.selectedActivity.startTime
      )?.toDate();
      data.EndTime = data.EndTime
        ? formatTimeToDateTime(
            data.EndTime,
            state.selectedActivity.endTime || state.selectedActivity.startTime
          )?.toDate()
        : null;

      try {
        await activityService.updateActivity(id, data);
      } catch (err: Error | any) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError(
            JSON.stringify(err || 'Encountered an error saving activity')
          );
        }
      }
      setLoading(false);
    },
    [activityService, setError, setLoading, state]
  );

  const deleteActivity = useCallback(
    async (activityId: number) => {
      setLoading(true);
      await activityService.deleteActivity(activityId);
      setLoading(false);
    },
    [activityService, setLoading]
  );

  const setActivityForm = useCallback(
    (activityForm: FormManager) => {
      dispatch(new Action(Actions.SET_ACTIVITYFORM, activityForm));
    },
    [dispatch]
  );

  const setDialogOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(new Action(Actions.SET_DIALOG_OPEN, isOpen));
    },
    [dispatch]
  );

  const setSelectedActivity = useCallback(
    (activity: IActivity) => {
      dispatch(new Action(Actions.SET_SELECTEDACTIVITY, activity));
    },
    [dispatch]
  );

  const setIsNewActivity = useCallback(
    (isNew: boolean) => dispatch(new Action(Actions.SET_ACTIVITYISNEW, isNew)),
    [dispatch]
  );

  const setDate = useCallback(
    async (newDate: Date) => {
      dispatch(new Action(Actions.SET_DATE, newDate));
      await getActivities(newDate);
    },
    [dispatch, getActivities]
  );

  const setDatePickOpen = useCallback(
    (isOpen: boolean) => dispatch(new Action(Actions.SET_DATEPICKOPEN, isOpen)),
    [dispatch]
  );

  return (
    <ActivityContext.Provider
      value={{
        state: state,
        actions: {
          getActivities,
          createActivity,
          deleteActivity,
          setActivityForm,
          setDialogOpen,
          setSelectedActivity,
          setIsNewActivity,
          updateActivity,
          setDate,
          setDatePickOpen,
        },
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

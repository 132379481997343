import { FC } from 'react';

import {
  HashRouter,
  Switch,
  Route
} from 'react-router-dom';

import { LoadingPage } from './pages/LoadingPage';
import { ErrorPage } from './pages/ErrorPage';
import { LaunchPage } from './pages/LaunchPage';

import { withAdalLoginApi } from './config';

import { PageContextProvider } from './context/PageContext';

import './theme';
import { Header } from './components/shared/Header';

const makeProtectedPage = (wrappedComponent: FC<any | {}>) =>
  withAdalLoginApi(wrappedComponent, () => <LoadingPage />, err => <ErrorPage error={typeof err === 'string' ? err : JSON.stringify(err)} />);

const ProtectedLaunchPage = makeProtectedPage(LaunchPage);

const App: FC = () =>
  <PageContextProvider>
    <Header />
    <HashRouter>
      <Switch>

        <Route>
          <ProtectedLaunchPage />
        </Route>

      </Switch>
    </HashRouter>
  </PageContextProvider>;

export { App };

import { Reducer } from 'react';
import { FormManager } from '../../services/FormManager';
import { Action } from '../../types/Action';
import { ITask } from '../../types/models/Task';

export const Actions = {
  SET_TASKS: 'SET_TASKS',
  SET_SELECTEDTASK: 'SET_SELECTEDTASK',
  SET_DIALOG_OPEN: 'SET_DIALOG_OPEN',
  SET_TASKFORM: 'SET_TASKFORM'
}

export interface ITaskState {
  tasks: ITask[];
  isInitialised: boolean;
  selectedTask: ITask;
  isDialogOpen: boolean;
  taskForm: FormManager;
}

export const initialState: ITaskState = {
  tasks: [],
  isInitialised: false,
  selectedTask: null,
  isDialogOpen: false,
  taskForm: new FormManager([
    { name: 'Name', value: '', isRequired: true },
    { name: 'Description', value: '', isRequired: false },
    { name: 'Budget.Hours', value: '', isRequired: false }
  ])
};

const reducer: Reducer<ITaskState, Action<any>> = (state, action) => {
  switch (action.type) {

    case Actions.SET_TASKS:
      return {
        ...state,
        tasks: action.payload,
        isInitialised: true
      };

    case Actions.SET_SELECTEDTASK:
      return {
        ...state,
        selectedTask: action.payload
      };

    case Actions.SET_DIALOG_OPEN:
      return {
        ...state,
        isDialogOpen: action.payload
      };

    case Actions.SET_TASKFORM:
      return {
        ...state,
        taskForm: action.payload
      };

    default:
      return state;
  }
};
export default reducer;
import { FC } from 'react';
import { IActivity } from '../../types/models/Activity';
import { List } from './List';

interface IProps {
  items?: IActivity[];
}

export const TotalTimeList: FC<IProps> = ({ items }) => {
  type TaskMapItem = { totalMilliseconds: number; taskName: string };
  type TaskMap = {
    [taskId: string]: TaskMapItem;
  };
  const taskMap = items.reduce((acc, item) => {
    if (Object.keys(acc).indexOf(item.task.taskId.toString()) === -1) {
      acc[item.task.taskId.toString()] = {
        totalMilliseconds: 0,
        taskName: item.task.name,
      };
    }

    if (item.endTime) {
      acc[item.task.taskId.toString()].totalMilliseconds +=
        new Date(item.endTime || undefined).getTime() - new Date(item.startTime).getTime();
    } else {
      acc[item.task.taskId.toString()].totalMilliseconds +=
        new Date().getTime() - new Date(item.startTime).getTime();
    }

    return acc;
  }, {} as TaskMap);

  const totalItems = Object.keys(taskMap).reduce((acc, k) => {
    return [
      ...acc ,{
      taskName: taskMap[k].taskName,
      totalHours: Math.round( taskMap[k].totalMilliseconds / 1000 / 60 / 60 * 100 ) / 100
    }];
  }, []);

  return (
    <List
      enabledActions={{ delete: false, edit: false }}
      columns={[
        {
          key: 'task',
          name: 'Task',
          minWidth: 20,
          maxWidth: 80,
          onRender: (item) => <span>{item.taskName || '-'}</span>,
        },
        {
          key: 'total',
          name: 'Total Hours',
          minWidth: 80,
          onRender: (item) => (
            <span>
              {item.totalHours}
            </span>
          ),
        },
      ]}
      items={totalItems}
    />
  );
};

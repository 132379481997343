import { FC } from 'react';
import { ITask } from '../../types/models/Task';
import { formatBudget } from '../../utils/taskHelpers';
import { List } from './List';

interface IProps {
  onTaskSelect: (task: ITask) => Promise<void> | void;
  onTaskDelete: (task: ITask) => Promise<void> | void;
  tasks?: ITask[];
}

export const TaskList: FC<IProps> = ({ onTaskSelect, onTaskDelete, tasks }) => {
  return (
    <List
      enabledActions={{ delete: true, edit: true }}
      columns={[
        {
          key: 'name',
          name: 'Name',
          minWidth: 20,
          maxWidth: 75,
          fieldName: 'name',
        },
        {
          key: 'description',
          name: 'Description',
          minWidth: 80,
          fieldName: 'description',
        },
        {
          key: 'budget',
          name: 'Budget',
          minWidth: 80,
          onRender: (item: ITask) => {
            let budget = item.budget || {};
            if (!budget.days) budget.days = 0;
            if (!budget.hours) budget.hours = 0;

            return <span>{formatBudget(budget)}</span>;
          },
        },
      ]}
      items={tasks}
      onSelect={onTaskSelect}
      onDelete={onTaskDelete}
    />
  );
};

import {
  Dropdown,
  IRenderFunction,
  ISelectableOption,
  SearchBox,
  Stack,
  TextField,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { FC, useContext, useMemo } from 'react';
import { PageContext } from '../../context/PageContext';
import { IActivity } from '../../types/models/Activity';
import { ITask } from '../../types/models/Task';
import { Dialog } from './Dialog';

interface IProps {
  isOpen: boolean;
  isNew: boolean;
  isValid: boolean;
  values?: { [key: string]: string | number };
  onSave: (data: IActivity) => Promise<void | any>;
  onDismiss: () => void;
  onFieldChanged: (name: string, newValue: string | number) => void;
  tasks: ITask[];
  forDate: Date;
}

export const ActivityDialog: FC<IProps> = ({
  isOpen,
  isNew,
  values,
  onDismiss,
  onSave,
  onFieldChanged,
  tasks,
  forDate,
  isValid,
}) => {
  const {
    state: { isLoading },
  } = useContext(PageContext);

  const renderSearchBox = () => {
    return (
      <SearchBox
        placeholder="Search tasks..."
        value={values['_query'] as string}
        autoFocus={true}
        onChange={(ev, newValue) => onFieldChanged('_query', newValue)}
      />
    );
  };

  const handleTaskRender: IRenderFunction<ISelectableOption> = (
    item,
    defaultRender
  ) => {
    if (!item && defaultRender) {
      return defaultRender(item);
    } else if (!item) {
      return null;
    }

    if (item.key === '<search>') {
      return renderSearchBox();
    } else if (defaultRender) {
      return defaultRender(item);
    } else {
      return null;
    }
  };

  const queryText = (values['_query'] as string) || '';
  const filteredTasks = useMemo(() => {
    const isMatch = (v: string) =>
      v.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    return tasks.filter((t) => isMatch(t.name) || isMatch(t.description));
  }, [tasks, queryText]);

  return (
    <Dialog
      isOpen={isOpen}
      title={isNew ? 'New activity' : 'Edit activity'}
      onDismiss={onDismiss}
      primaryAction={{
        text: isNew ? 'Create' : 'Save',
        iconProps: { iconName: isNew ? 'Accept' : 'Save' },
        disabled: isLoading || !isValid,
        onClick: async () => {
          await onSave(null);
          onDismiss();
        },
      }}
      subText={`Complete the fields below to ${
        isNew ? 'create a new ' : 'confirm changes to the '
      } activity.`}
    >
      {/* Name & description */}
      <TextField
        label="Overview"
        multiline={true}
        autoAdjustHeight={true}
        rows={3}
        onChange={(ev, newValue) => onFieldChanged('Overview', newValue)}
        value={values['Overview'] as string}
        required
      />

      {/* Task selector */}
      <Dropdown
        label="Task"
        options={[
          {
            key: '<search>',
            text: '',
            disabled: true,
          },
          ...(filteredTasks.map((t) => ({
            key: t.taskId,
            text: `${t.name}: ${t.description}`,
            selected: values['TaskId'] === t.taskId,
          })) || []),
        ]}
        onRenderItem={handleTaskRender}
        onChange={(ev, opt) => onFieldChanged('TaskId', opt.key)}
        required
      />

      {/* Start/end time */}
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <TextField
          label="Date"
          value={dayjs(forDate).format('DD MMM YYYY')}
          styles={{ root: { width: 100 } }}
          disabled
        />
        <TextField
          label="Start time"
          value={values['StartTime'] as string}
          onChange={(ev, newValue) => onFieldChanged('StartTime', newValue)}
          required
        />
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <TextField
          label="Date"
          value={dayjs(forDate).format('DD MMM YYYY')}
          styles={{ root: { width: 100 } }}
          disabled
        />
        <TextField
          label="End time"
          value={values['EndTime'] as string}
          onChange={(ev, newValue) => onFieldChanged('EndTime', newValue)}
        />
      </Stack>
    </Dialog>
  );
};

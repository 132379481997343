import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { TaskService } from '../services/TaskService';
import { ITask } from '../types/models/Task';
import reducer, { Actions, ITaskState, initialState } from './state/TaskState';
import { PageContext } from './PageContext';
import { Action } from '../types/Action';
import { FormManager } from '../services/FormManager';

export interface ITaskContext {
  state: ITaskState;
  actions: {
    getTasks(): Promise<ITask[]>;
    createTask(task: ITask): Promise<void>;
    updateTask(taskId: number, task: ITask): Promise<void>;
    deleteTask(task: ITask): Promise<void>;
    setSelectedTask(task: ITask): void;
    setDialogOpen(isOpen: boolean): void;
    setTaskForm(taskForm: FormManager): void;
  };
}

export const TaskContext = createContext<ITaskContext>({} as ITaskContext);

export const TaskContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    actions: { setLoading, setError },
  } = useContext(PageContext);

  const taskService = useMemo(() => new TaskService(), []);

  const getTasks = useCallback(async () => {
    setLoading(true);
    try {
      const tasks = await taskService.getTasks();
      dispatch({ type: Actions.SET_TASKS, payload: tasks });
      return tasks;
    } catch (err) {
      console.error('Error fetching tasks', err);
      setError(err?.message || 'Error fetching tasks');
    } finally {
      setLoading(false);
    }
  }, [setLoading, taskService, setError]);

  const createTask = useCallback(
    async (task: any) => {
      setLoading(true);
      try {
        // first of all, do a validation..
        if (!task) {
          throw new Error('Application error: Cannot save, invalid task');
        }

        if (task.Budget && task.Budget.Hours) {
          let days =
            Math.trunc(task.Budget.Hours / 24) + (task.Budget.Days || 0);
          let hours = task.Budget.Hours % 24;
          task.Budget = { Days: days, Hours: hours };
        }

        await taskService.createTask(task);
        await getTasks();
      } catch (err) {
        console.error('Error creating task', err);
        setError(err.message || 'Error creating task');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, taskService, getTasks, setError]
  );

  const updateTask = useCallback(
    async (taskId: number, task: any) => {
      setLoading(true);
      try {
        // first of all, do a validation..
        if (!task)
          throw new Error('Application error: Cannot save, invalid task');

        if (task.Budget && task.Budget.Hours) {
          let days =
            Math.trunc(task.Budget.Hours / 24) + (task.Budget.Days || 0);
          let hours = task.Budget.Hours % 24;
          task.Budget = { Days: days, Hours: hours };
        }

        await taskService.updateTask(taskId, task);
        await getTasks();
      } catch (err) {
        console.error('Error updating task', err);
        setError(err.message || 'Error updating task');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, taskService, getTasks, setError]
  );

  const deleteTask = useCallback(
    async (task: ITask) => {
      setLoading(true);
      try {
        await taskService.deleteTask(task.taskId);
        await getTasks();
      } catch (err) {
        console.error(err);
        setError(err.message || 'Error deleting task - may be related to existing activities');
      } finally {
        setLoading(false);
      }
    },
    [taskService, setLoading, getTasks, setError]
  );

  const setSelectedTask = useCallback(
    (task: ITask) => dispatch(new Action(Actions.SET_SELECTEDTASK, task)),
    [dispatch]
  );

  const setDialogOpen = useCallback(
    (isOpen: boolean) => dispatch(new Action(Actions.SET_DIALOG_OPEN, isOpen)),
    [dispatch]
  );

  const setTaskForm = useCallback(
    (taskForm: FormManager) =>
      dispatch(new Action(Actions.SET_TASKFORM, taskForm)),
    [dispatch]
  );

  return (
    <TaskContext.Provider
      value={{
        state,
        actions: {
          getTasks,
          createTask,
          updateTask,
          deleteTask,
          setSelectedTask,
          setDialogOpen,
          setTaskForm,
        },
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

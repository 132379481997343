import {
  AuthenticationContext,
  AdalConfig,
  adalFetch,
  withAdalLogin
} from 'react-adal';
import { appConfig } from './appConfig';

const adalConfig: AdalConfig = {
  tenant: appConfig.authentication.directoryId,
  clientId: appConfig.authentication.clientId,
  endpoints: {
    // this is the resource
    api: appConfig.authentication.resource
  },
  cacheLocation: 'localStorage',
  redirectUri: appConfig.authentication.redirectUrl
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch: (input: string, init: any) => Promise<any>, url: string, options: any) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

import dayjs from 'dayjs';
import { FC } from 'react';
import { IActivity } from '../../types/models/Activity';
import { List } from './List';

interface IProps {
  onSelect: (activity: IActivity) => Promise<void> | void;
  onDelete: (activity: IActivity) => Promise<void> | void;
  items?: IActivity[];
  isDeleteEnabled?: boolean;
  isEditEnabled?: boolean;
  timeFormat?: string;
  timeColWidth?: number;
}

export const ActivityList: FC<IProps> = ({
  onDelete,
  items,
  onSelect,
  isDeleteEnabled,
  isEditEnabled,
  timeFormat,
  timeColWidth,
}) => {
  return (
    <List
      enabledActions={{
        delete: typeof isDeleteEnabled === 'boolean' ? isDeleteEnabled : true,
        edit: typeof isEditEnabled === 'boolean' ? isEditEnabled : true,
      }}
      columns={[
        {
          key: 'task',
          name: 'Task',
          minWidth: 20,
          maxWidth: 80,
          onRender: (item: IActivity) => <span>{item.task?.name || '-'}</span>,
        },
        {
          key: 'overview',
          name: 'Overview',
          minWidth: 80,
          isMultiline: true,
          onRender: (item: IActivity) => (
            <span style={{ whiteSpace: 'pre-line' }}>{item.overview}</span>
          ),
        },
        {
          key: 'startTime',
          name: 'Start',
          minWidth: timeColWidth || 80,
          maxWidth: timeColWidth || 80,
          onRender: (item: IActivity) => (
            <span>{dayjs(item.startTime).format(timeFormat || 'hh:mm A')}</span>
          ),
        },
        {
          key: 'endTime',
          name: 'End',
          minWidth: timeColWidth || 80,
          maxWidth: timeColWidth || 80,
          onRender: (item: IActivity) => (
            <span>
              {!!item.endTime ? dayjs(item.endTime).format(timeFormat || 'hh:mm A') : '-'}
            </span>
          ),
        },
      ]}
      items={items}
      onSelect={onSelect}
      onDelete={onDelete}
    />
  );
};

import { FC } from 'react';

export const Header: FC = ({ children }) => {
  return (
    <>
      <div>
        <img
          style={{ maxWidth: 200, display: 'block', margin: '20px auto' }}
          src="/banner.png"
          alt="Banner"
        />
      </div>
      {children}
    </>
  );
};

import { FC, useContext } from 'react';
import {
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IIconProps,
  ContextualMenu
} from '@fluentui/react';
import { PageContext } from '../../context/PageContext';

export type DialogAction = {
  text: string;
  disabled: boolean;
  onClick: () => Promise<void>;
  iconProps?: IIconProps;
};

export interface IDialogProps {
  isOpen: boolean;
  title: string;
  subText?: string;
  primaryAction: DialogAction;
  onDismiss: () => void;
}

const AppDialog: FC<IDialogProps> = ({
  isOpen,
  title,
  subText,
  primaryAction,
  onDismiss,
  children
}) => {

  const {
    state: { isLoading }
  } = useContext(PageContext);

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        showCloseButton: true,
        title,
        subText,
      }}
      maxWidth="calc(100% - 32px)"
      modalProps={{
        isBlocking: false,
        dragOptions: {
          closeMenuItemText: 'Close',
          moveMenuItemText: 'Move',
          menu: ContextualMenu
        }
      }}
    >
      {children}
      <DialogFooter>
        <PrimaryButton
          text={primaryAction.text}
          disabled={primaryAction.disabled}
          onClick={() => primaryAction.onClick()}
          iconProps={primaryAction.iconProps}
        />
        <DefaultButton
          text="Dismiss"
          disabled={isLoading}
          onClick={onDismiss}
          iconProps={!!primaryAction.iconProps ? { iconName: 'Cancel' } : undefined}
        />
      </DialogFooter>
    </Dialog>
  );
};

export { AppDialog as Dialog }
import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import reportWebVitals from './reportWebVitals';
import { runWithAdal } from 'react-adal';
import { authContext } from './config';

import { App } from './App';
import './index.css';

initializeIcons(undefined, { disableWarnings: false });

const loginState = {
  enabled: false,
  disabled: true
};
runWithAdal(authContext, () => ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
), loginState.enabled);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

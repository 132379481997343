import { createContext, FC, useCallback, useContext, useMemo, useReducer } from 'react';
import { ActivityService } from '../services/ActivityService';
import { Action } from '../types/Action';
import { PageContext } from './PageContext';
import reducer, {
  Actions,
  defaultState,
  ISearchState,
} from './state/SearchState';

export interface ISearchContext {
  state: ISearchState;
  actions: {
    setQuery: (q: string) => void;
    search: () => Promise<void>;
  };
}

export const SearchContext = createContext<ISearchContext>(
  {} as ISearchContext
);

export const SearchContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const {
    actions: { setLoading, setError },
  } = useContext(PageContext);

  const activityService = useMemo(() => new ActivityService(), []);

  const search = useCallback(async () => {
    setLoading(true);

    await activityService.searchActivities(state.query)
      .then(results => dispatch(new Action(Actions.SET_SEARCHRESULTS, results)))
      .catch(err => setError(err?.message || 'Failed to search'))
      .finally(() => setLoading(false));
  }, [setLoading, setError, dispatch, activityService, state.query]);

  const setQuery = useCallback(
    (q: string) => dispatch(new Action(Actions.SET_QUERY, q)),
    []
  );

  return (
    <SearchContext.Provider
      value={{
        state: state,
        actions: {
          search,
          setQuery,
        },
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

import { IconButton, SearchBox, Stack, StackItem } from '@fluentui/react';
import { FC, useContext } from 'react';
import { ActivityList } from '../../components/lists/ActivityList';
import { PageContext } from '../../context/PageContext';
import { SearchContext } from '../../context/SearchContext';

export const SearchPage: FC = () => {
  const {
    state: { query, results },
    actions: { setQuery, search },
  } = useContext(SearchContext);

  const {
    state: { isLoading },
  } = useContext(PageContext);

  return (
    <div>
      <div style={{ padding: '20px 5px 10px 20px' }}>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <StackItem grow={1}>
              <SearchBox
                placeholder="Enter search query..."
                value={query}
                onChange={(ev, newValue) => setQuery(newValue)}
                disabled={isLoading}
              />
            </StackItem>
            <StackItem grow={0}>
              <IconButton
                primary={true}
                iconProps={{ iconName: 'Search' }}
                onClick={search}
                disabled={isLoading}
              />
            </StackItem>
          </Stack>
          <ActivityList
            isDeleteEnabled={false}
            isEditEnabled={false}
            onDelete={(x) => console.log(x)}
            onSelect={(x) => console.log(x)}
            items={results}
            timeFormat="DD/MM/YY hh:mm A"
            timeColWidth={105}
          />
        </Stack>
      </div>
    </div>
  );
};

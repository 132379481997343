import dayjs from 'dayjs';
import { Reducer } from 'react';
import { FormManager } from '../../services/FormManager';
import { Action } from '../../types/Action';
import { IActivity } from '../../types/models/Activity';

export const Actions = {
  SET_ACTIVITIES: 'SET_ACTIVITIES',
  SET_SELECTEDACTIVITY: 'SET_SELECTEDACTIVITY',
  SET_DIALOG_OPEN: 'SET_DIALOG_OPEN',
  SET_ACTIVITYFORM: 'SET_ACTIVITYFORM',
  SET_ACTIVITYISNEW: 'SET_ACTIVITYISNEW',
  SET_DATE: 'SET_DATE',
  SET_DATEPICKOPEN: 'SET_DATEPICKOPEN',
};

export interface IActivityState {
  activities: IActivity[];
  isInitialised: boolean;
  selectedActivity: IActivity;
  isDialogOpen: boolean;
  activityForm: FormManager;
  date: Date;
  isActivityNew: boolean;
  isDatePickOpen: boolean;
}

export const initialState: IActivityState = {
  date: dayjs().hour(0).toDate(),
  activities: [],
  isInitialised: false,
  selectedActivity: null,
  isDialogOpen: false,
  activityForm: new FormManager([
    { name: 'Overview', value: '', isRequired: true },
    { name: 'StartTime', value: '', isRequired: true },
    { name: 'EndTime', value: '', isRequired: false },
    { name: 'TaskId', value: '', isRequired: true },
    { name: '_query', value: '', isRequired: false },
  ]),
  isActivityNew: true,
  isDatePickOpen: false,
};

const reducer: Reducer<IActivityState, Action<any>> = (state, action) => {
  switch (action.type) {
    case Actions.SET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
        isInitialised: true,
      };

    case Actions.SET_SELECTEDACTIVITY:
      return {
        ...state,
        selectedActivity: action.payload,
      };

    case Actions.SET_DIALOG_OPEN:
      return {
        ...state,
        isDialogOpen: action.payload,
      };

    case Actions.SET_ACTIVITYISNEW:
      return {
        ...state,
        isActivityNew: action.payload,
      };

    case Actions.SET_ACTIVITYFORM:
      return {
        ...state,
        activityForm: action.payload,
      };

    case Actions.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case Actions.SET_DATEPICKOPEN:
      return {
        ...state,
        isDatePickOpen: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;

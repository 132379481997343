interface IFormFieldDefinition {
  name: string;
  value: any;
  isRequired: boolean;
}

export interface IFormField extends IFormFieldDefinition {
  isDirty: boolean;
}

export class FormManager {
  public fields: IFormField[];

  constructor(fields?: IFormFieldDefinition[]) {
    this.fields = fields.map((f) => ({
      ...f,
      isDirty: false,
    }));
  }

  public addField = (field: IFormField) => {
    this.fields = [...this.fields, field];
  };

  public isValid = () => {
    return this.fields.every(
      (f) => (f.isRequired && !!f.value) || !f.isRequired
    );
  };

  public setFieldValue = (name: string, newValue: any) => {
    this.fields.find((f) => f.name === name).value = newValue;
  };

  public handleFieldChanged = (name: string, newValue: any) => {
    this.setFieldValue(name, newValue);
  };

  public resetField = (name: string) => {
    const field = this.fields.find((f) => f.name === name);
    field.value = '';
    field.isDirty = false;
  };

  public reset = () => {
    this.fields.map(({ name }) => name).forEach(this.resetField);
  };

  public value = <T>(name: string): T => {
    return this.fields.find((f) => f.name === name).value as T;
  };

  private getObjectValue = (key: string, value: any): any => {
    const segments = key.split('.');
    if (segments.length === 1) {
      return { [key]: value };
    }

    return {
      [segments[0]]: this.getObjectValue(segments.slice(1).join('.'), value),
    };
  };

  public getValues = () => {
    return this.fields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.name]: curr.value,
      };
    }, {});
  };

  public get = <T>() => {
    return this.fields.reduce(
      (acc, curr) => ({
        ...acc,
        ...this.getObjectValue(curr.name, curr.value),
      }),
      {}
    ) as T;
  };
}

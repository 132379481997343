import { FC, useContext } from 'react';
import {
  DetailsList,
  IColumn,
  IconButton,
  SelectionMode,
  Stack,
} from '@fluentui/react';

import { PageContext } from '../../context/PageContext';

interface IProps {
  items: any;
  columns: IColumn[];
  onSelect?: (item: any) => Promise<void> | void;
  onDelete?: (item: any) => Promise<void> | void;
  enabledActions: {
    delete: boolean;
    edit: boolean;
  };
}

export const List: FC<IProps> = ({
  items,
  columns,
  onSelect,
  onDelete,
  enabledActions,
}) => {
  const {
    state: { isLoading },
  } = useContext(PageContext);

  if (isLoading) {
    return <></>;
  }

  if (enabledActions.delete || enabledActions.edit) {
    columns = [
      ...columns,
      {
        key: 'actions',
        name: 'Actions',
        minWidth: 60,
        onRender: (itm) => (
          <Stack horizontal>
            {enabledActions.edit && onSelect && (
              <IconButton
                title="Edit"
                iconProps={{ iconName: 'Edit' }}
                onClick={() => onSelect(itm)}
                disabled={isLoading}
              />
            )}
            {enabledActions.delete && onDelete && (
              <IconButton
                title="Delete"
                iconProps={{ iconName: 'Delete' }}
                onClick={() => onDelete(itm)}
                disabled={isLoading}
              />
            )}
          </Stack>
        ),
      },
    ];
  }

  return (
    <DetailsList
      columns={columns}
      items={items}
      selectionMode={SelectionMode.none}
    />
  );
};

import { FC, createContext, Context, useCallback, useReducer } from 'react';
import reducer, { Actions, defaultState, IPageState } from './state/PageState';
import { Action } from '../types/Action';
import { ConfirmationModal } from '../components/shared/ConfirmationModal';
import { MessageBar, MessageBarType, Text } from '@fluentui/react';
import { appConfig } from '../config';
import { Loading } from '../components/shared/Loading';

export interface IPageContext {

  state: IPageState;
  actions: {
    setLoading(isLoading: boolean): void;
    confirmAction(
      title: string,
      message: string,
      onConfirmAsync: () => Promise<void | any>
    ): void;
    setError(message: string): void;
    clearError(): void;
  }
}

export const PageContext: Context<IPageContext> =
  createContext<IPageContext>({} as IPageContext);

export const PageContextProvider: FC = ({ children }) => {

  const [ state, dispatch ] = useReducer(reducer, defaultState);

  const setLoading = useCallback(async (isLoading: boolean) => {
    dispatch(new Action<boolean>(Actions.SET_ISLOADING, isLoading));
  }, [ dispatch ]);

  const confirmAction = useCallback((
    title: string,
    message: string,
    onConfirmAsync: () => Promise<void | any>
  ) => {
    if (state.confirmationModal.isOpen) {
      throw new Error('Application error: Confirmation dialog already visible. Please wait for current modal to complete before initiating a new confirmation');
    }

    dispatch(new Action(Actions.SET_CONFIRMDIALOG_STATE, {
      isOpen: true,
      title,
      message,
      onConfirmAsync
    }));
  }, [dispatch, state.confirmationModal.isOpen]);

  const closeConfirmationModal = useCallback(() => {
    dispatch(new Action(Actions.SET_CONFIRMDIALOG_STATE, {
      isOpen: false,
      title: '',
      message: '',
      onConfirm: () => {}
    }));
  }, [dispatch]);

  const clearError = useCallback(() => {
    dispatch(new Action(Actions.SET_ERRORSTATE, {
      active: false,
      message: ''
    }));
  }, [dispatch]);

  const setError = useCallback((message: string) => {
    dispatch(new Action(Actions.SET_ERRORSTATE, {
      active: true,
      message
    }));
  }, [dispatch]);
  
  return (
    <PageContext.Provider
      value={{
        actions: {
          setLoading,
          confirmAction,
          setError,
          clearError
        },
        state
      }}
    >
      <div style={{ position: 'absolute', right: 7, top: 7 }}>
        <Text styles={{ root: { fontSize: '.8em', color: '#aaa' } }}>{appConfig.uiVersion}</Text>
      </div>
      {state.errorState.active && (
        <MessageBar
          messageBarType={MessageBarType.severeWarning}
          onDismiss={clearError}
        >
          {state.errorState.message || 'Application error. Please contact your system administrator.'}
        </MessageBar>
      )}
      {children}
      {state.isLoading && 
        <Loading />
      }
      <ConfirmationModal
        isOpen={state.confirmationModal.isOpen}
        title={state.confirmationModal.title}
        message={state.confirmationModal.message}
        onConfirmAsync={state.confirmationModal.onConfirmAsync}
        onDismiss={closeConfirmationModal}
      />
    </PageContext.Provider>
  );
}